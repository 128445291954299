
@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-weight: var(--RS-font-weight-variable-bold);
  position: relative;
  padding: var(--RS-space-32) 0 0 0;

  @media (--md-viewport) {
    /* width: auto;
    min-width: calc(var(--RS-space-128) + var(--RS-space-32)); */
  }
}

.RS-list li{
  padding: 0 0 var(--RS-space-16) 0;

  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-p-desktop);
  }

}

.RS-list--tiles{
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: var(--RS-space-32);
}

.RS-list--tiles li{
  padding: var(--RS-space-16);
  width: 100%;
  border-radius: var(--RS-space-6);

  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);

  background-color: var(--RS-light-grey-color);

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-p-desktop);
  }
}