/* Variables */

:root {
  /* Spaces */
  --RS-space-1: 1px;
  --RS-space-2: 2px;
  --RS-space-4: 4px;
  --RS-space-6: 6px;
  --RS-space-8: 8px;
  --RS-space-12: 12px;
  --RS-space-16: 16px;
  --RS-space-24: 24px;
  --RS-space-32: 32px;
  --RS-space-48: 48px;
  --RS-space-64: 64px;
  --RS-space-96: 96px;
  --RS-space-128: 128px;
  --RS-space-192: 192px;
  --RS-space-256: 256px;

  --RS-space-1-n: -1px;
  --RS-space-2-n: -2px;
  --RS-space-4-n: -4px;
  --RS-space-6-n: -6px;
  --RS-space-8-n: -8px;
  --RS-space-12-n: -12px;
  --RS-space-16-n: -16px;
  --RS-space-24-n: -24px;
  --RS-space-32-n: -32px;
  --RS-space-48-n: -48px;
  --RS-space-64-n: -64px;
  --RS-space-96-n: -96px;
  --RS-space-128-n: -128px;
  --RS-space-192-n: -192px;
  --RS-space-256-n: -256px;

  /* Grid */
  --RS-col-1: 160px;
  --RS-col-2: 320px;
  --RS-col-3: 480px;
  --RS-col-4: 640px;
  --RS-col-5: 800px;
  --RS-col-6: 960px;
  --RS-col-7: 1120px;
  --RS-col-8: 1280px;

  /* Main colors */
  --RS-white-color: #e8eaed;
  --RS-black-color: #01060e;
  --RS-light-grey-color: #dbdcdd;
  --RS-dark-grey-color: #838485;
  --RS-graphite-color: #161e2b;
  --RS-accent-color-primary: #b92740;
  --RS-accent-color-secondary: #b92740;

  /* Brand colors */
  --RS-brand-color-sample: #7977b9;


/* Motion */
  --RS-anim-delay: 50ms;
  --RS-default-transition: cubic-bezier(0.39, 0.575, 0.565, 1);
  --RS-bounce-transition: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  --RS-anim-duration-xs: 75ms;
  --RS-anim-duration-sm: 150ms;
  --RS-anim-duration-md: 225ms;
  --RS-anim-duration-lg: 350ms;
  --RS-anim-duration-xlg: 600ms;
  --RS-anim-intro-artwork-delay: 750ms;
  --RS-anim-full-rotate-duration: 90000ms;

  /* Typography */
  --RS-line-height-sm: 1;
  --RS-line-height-md: 1.21;
  --RS-line-height-lg: 1.34;
  --RS-line-height-xlg: 1.55;

  --RS-font-size-h1: clamp(3.125rem, 2.8036rem + 1.4286vw, 4.5rem);; /* 50 - 72px, minmax 360 - 1900px */
  --RS-font-size-h1-desktop: clamp(4.25rem, 3.7339rem + 0.6452vw, 4.5rem); /* 68 - 72px, minmax 1280 - 1900px */
  --RS-font-size-h1-intro: clamp(2rem, 1.5617rem + 1.9481vw, 3.875rem);/* 32 - 62px, minmax 360 - 1900px */
  --RS-font-size-h1-intro-desktop: clamp(3.625rem, 3.1089rem + 0.6452vw, 3.875rem);/* 58 - 62px, minmax 1280 - 1900px */
  --RS-font-size-h2: clamp(1.3125rem, 1.2468rem + 0.2922vw, 1.59375rem); /* 21 - 25.5px, minmax 360 - 1900px */
  --RS-font-size-h2-desktop: clamp(1.5rem, 1.2419rem + 0.3226vw, 1.625rem); /* 24 - 26px, minmax 1280 - 1900px */
  --RS-font-size-h3: clamp(.75rem, .7135rem + .1623vw, .90625rem);  /* 12 - 14.5px, minmax 360 - 1900px */
  --RS-font-size-h3-desktop: clamp(0.875rem, 0.746rem + 0.1613vw, 0.9375rem);  /* 14 - 15px, minmax 1280 - 1900px */
  --RS-font-size-top-nav: clamp(0.875rem, 0.8458rem + 0.1299vw, 1rem); /* 14 - 16px, minmax 360 - 1900px */
  --RS-font-size-mobile-nav: clamp(2.625rem, 2.2772rem + 1.7391vw, 3.125rem); /* 42 - 48px, minmax 320 - 780px */
  --RS-font-size-p: clamp(0.9375rem, 0.9083rem + 0.1299vw, 1.0625rem); /* 15 - 17px, minmax 360 - 1900px */
  --RS-font-size-p-desktop: clamp(1.03125rem, 0.9022rem + 0.1613vw, 1.09375rem); /* 16 - 17px, minmax 1280 - 1900px */
  --RS-font-size-label: clamp(.875rem, .8531rem + .0974vw, .96875rem); /* 14 - 15.5px, minmax 360 - 1900px */
  --RS-font-size-label-desktop: clamp(0.9375rem, 0.8085rem + 0.1613vw, 1rem); /* 15 - 16px, minmax 1280 - 1900px */
  --RS-font-size-stat: clamp(2.5rem, 2.2516rem + 1.1039vw, 3.5625rem); /* 40 - 57px, minmax 360 - 1900px */
  --RS-font-size-disclaimer: clamp(0.75rem, 0.7354rem + 0.0649vw, 0.8125rem); /* 12 - 13px, minmax 360 - 1900px */

  --RS-font-weight-variable-regular: 375;
  --RS-font-weight-variable-bold: 650;

  /* Misc. */
  --RS-brand-bg-xy: calc(var(--RS-space-24) - var(--RS-space-8));
  --RS-rotate-firefox-fix: 0deg;

}

/* @custom-selector :hover (:hover, :focus-visible); */


